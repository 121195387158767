import './App.css';
import Friends from './components/Friends';
import Earnings from './components/layouts/earnings';
import Machine from './components/Machine';
import Money_info from './components/money_info';
import Panel from './components/panel';
import ProgresBar from './components/ProgresBar';
import ToDoList from './components/ToDoList';
import Profile from './components/Profile'
import {memo, useEffect, useState} from "react";

const tg = window.Telegram.WebApp;
const u = tg.initDataUnsafe?.user;

let alertFlag = false;

// Ссылка на фото профиля
try{
  let data = await fetch(`https://api.spin2win.tech/GetUser/${tg.initDataUnsafe?.user?.id}`, {
    headers: {
        'Check-String': window.Telegram.WebApp.initData ? window.Telegram.WebApp.initData : ""
    }
});
  if (data.ok){

  }else{
    let data = await fetch(`https://api.spin2win.tech/AddUser/${tg.initDataUnsafe?.user?.id}`, {method: 'POST',
      headers: {
          'Check-String': window.Telegram.WebApp.initData ? window.Telegram.WebApp.initData : ""
      },
      body: JSON.stringify({ 
        'name': `${u.first_name} ${u.last_name}`, 
        'img_url': `https://api.spin2win.tech/img/Avatar.png`})
      });
      if (data.ok){

      const startParam = tg.initDataUnsafe?.start_param;
      if (startParam && startParam.length !== 0){
            const res = await fetch(`https://api.spin2win.tech/AddFriend/${startParam}`, {
              method: 'POST', 
              headers: { 
                  'Content-Type': 'application/json', 
                  'Check-String': tg.initData || "" 
              }, 
              body: JSON.stringify({ 
                  "_id": Number(tg.initDataUnsafe.user.id), 
                  'name': `${u.first_name} ${u.last_name}`, 
                  'img_url': `https://api.spin2win.tech/img/Avatar.png` 
              })
          }); 
          
      }}}
  
  }catch{
  
}


let data = await fetch(`https://api.spin2win.tech/GetUser/${tg.initDataUnsafe?.user?.id}`, {
    headers: {
        'Check-String': window.Telegram.WebApp.initData ? window.Telegram.WebApp.initData : ""
    }
});
let user = await data.json();
const App = memo(() => {
  const [balanceUpdater, setBalanceUpdater] = useState(0);
  const [us, setUser] = useState(user);
  const [MainPageOpenned, setMainPageOpenned] = useState(true);
  const [ToDoListOpenned, setToDoListOpenned] = useState(false);
  const [EarningsOpenned, setEarningsOpenned] = useState(false);
  const [FriendsOpenned, setFriendsOpenned] = useState(false);
  const [ProfileOpenned, setProfileOpenned] = useState(false);
  const [balance, setBalance] = useState(user.balance);

  async function stu() {
    const tg = window.Telegram.WebApp;
    try {
      let data = await fetch(`https://api.spin2win.tech/GetUser/${tg.initDataUnsafe?.user?.id}`, {
        headers: {
          'Check-String': window.Telegram.WebApp.initData ? window.Telegram.WebApp.initData : ""
        }
      });
      let user = await data.json();
      return user;
    } catch {
      // Обработка ошибок
    }
  }

  const update_user = async () => {
    const tg = window.Telegram.WebApp;
    let req = await fetch(`https://api.spin2win.tech/UpdateSession/${tg.initDataUnsafe?.user?.id}`, {
      method: 'POST',
      headers: {
        'Check-String': window.Telegram.WebApp.initData ? window.Telegram.WebApp.initData : ""
      }
    });
    
    if (req.ok) {
      if (!alertFlag) {
        let req_json = await req.json();
        if (req_json['earned'] !== null && req_json['earned'] !== undefined && req_json['earned'] !== 0) {
          alert(`Заработано: ${req_json['earned'] ? req_json['earned'] : '0'}`);
        }
        alertFlag = true;
      }
      let data = await fetch(`https://api.spin2win.tech/GetUser/${tg.initDataUnsafe?.user?.id}`, {
        headers: {
          'Check-String': window.Telegram.WebApp.initData ? window.Telegram.WebApp.initData : ""
        }
      });
      
      if (data.ok) {
        return await data.json();
      } else {
      }
    }
    
    return null; // Возвращаем null в случае ошибки
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const userData = await update_user();
      if (userData) {
        setUser(userData); // Обновляем состояние с новым пользователем
      }
    };

    fetchUserData(); // Запускаем fetchUserData при старте

    const intervalId = setInterval(fetchUserData, 121000); // Обновление каждые 2 минуты

    return () => clearInterval(intervalId); // Очистка интервала при размонтировании компонента
  }, [balanceUpdater]);

  const EarnLay = () => {
    setEarningsOpenned(true);
    setFriendsOpenned(false);
    setToDoListOpenned(false);
    setProfileOpenned(false);
  }
  const ToDoLay = () => {
    setEarningsOpenned(false);
    setFriendsOpenned(false);
    setToDoListOpenned(true);
    setProfileOpenned(false);
  }
  const FriendsLay = () => {
    setEarningsOpenned(false);
    setFriendsOpenned(true);
    setToDoListOpenned(false);
    setProfileOpenned(false);
  }
  const ProfLay = () => {
    setEarningsOpenned(false);
    setFriendsOpenned(false);
    setToDoListOpenned(false);
    setProfileOpenned(true);
  }
  const MainLay = () => {
    setEarningsOpenned(false);
    setFriendsOpenned(false);
    setToDoListOpenned(false);
    setProfileOpenned(false);
  }
  const increment = (num) =>{
    setBalance(balance + num);
    return balance + num;
  }

  return (
    <div className="App">
      <Money_info data={us}/>
      <ProgresBar data={us} />
      <Machine data={us} balance={increment} balanceUpdater={setBalanceUpdater}/>


      

      {EarningsOpenned ? <Earnings data={us} tg={tg} balanceUpdater={setBalanceUpdater}/> : null}
      {ToDoListOpenned ? <ToDoList data={us} tg={tg}/> : null}
      {FriendsOpenned ? <Friends data={us} tg={tg}/> : null}
      {ProfileOpenned ? <Profile data={us} tg={tg}/> : null}

      <Panel  
        onClickEarnLay={EarnLay} 
        onClickProfLay={ProfLay}
        onClickToDoLay={ToDoLay} 
        onClickFriendsLay={FriendsLay} 
        onClickMainLay={MainLay}
      />
    </div>
  );
})

export default App;

