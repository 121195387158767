import { memo, useEffect, useState } from "react";
import { TelegramShareButton, TelegramIcon, VKShareButton, VKIcon, FacebookIcon, FacebookShareButton, ViberIcon, ViberShareButton } from "react-share";

const Friends = memo(({data}) => {
    const [openShare, setOpenShare] = useState(false)
    const [fr, setFr] = useState([])
    const [loading, setLoading] = useState(true);
    const handleShare = async () => {
        try {
            await navigator.share({
                text: text,
                url: url
            });
        } catch (error) {
            console.error("Error sharing:", error);
        }
    };
    useEffect(() => {
        async function getFriends() {
            
            try {
                const req = await fetch(`https://api.spin2win.tech/GetFriends/${window.Telegram.WebApp.initDataUnsafe?.user?.id}`, {
                    method: 'GET',
                    headers: {
                        'Check-String': window.Telegram.WebApp.initData ? window.Telegram.WebApp.initData : ""
                    }
                })
                if (req.ok) {
                    const res = await req.json();
                    setFr(res)
                } else {
                    console.error("Failed to fetch data");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        }

        getFriends();
    }, []);
    const url = `https://t.me/Spin2Win_bot/spin2win?startapp=${window.Telegram.WebApp.initDataUnsafe?.user?.id}`
    const text = 'Im working'
    return (
        <div className="layout_fr">
        <span className="fr_main_title">Приглашайте друзей!</span>
        <span className="fr_main_description">Вы и ваш друг получите награду</span>
           <div className="card_fr" onClick={() => setOpenShare(true)}>
            <img className="fr_image" src="img\Подарок 2.png" srcSet="img\Подарок 2_2x.png 2x"/>
            <div className="fr_text">
                <span className="fr_title">Пригласите друга</span>
                <span className="fr_description">Вы и ваш друг получите по </span>
                <div className="fr_price">
                    <img className="mny" src="/img/Moneta3%201.png" srcSet="/img/Moneta3 1_2x.png 2x"/>
                    <span>+ 30 000</span>
                </div>
            </div>
        </div>
        <div className="layout_fr" onClick={() => setOpenShare(false)} style={{display: openShare ? 'flex' : 'none'}}>
            <div className="share-panel">
                <TelegramShareButton style={{margin:"3%", display: 'flex', flexDirection: 'column', height:'fit-content', width: '15%'}} url={url} text={text}>
                    <TelegramIcon width={"100%"} height={"100%"}  round={true}></TelegramIcon>
                    <span style={{color: 'black', padding: '5%', textAlign: 'center', marginLeft: 'auto', marginRight:'auto'}}>Telegram</span>
                </TelegramShareButton>
                <VKShareButton style={{margin:"3%", display: 'flex', flexDirection: 'column', height:'fit-content', width: '15%'}} url={url} text={text}>
                    <VKIcon width={"100%"} height={"100%"} round={true}></VKIcon>
                    <span style={{color: 'black', padding: '5%', textAlign: 'center', marginLeft: 'auto', marginRight:'auto'}}>VK</span>
                </VKShareButton>
                <ViberShareButton style={{margin:"3%", display: 'flex', flexDirection: 'column', height:'fit-content', width: '15%'}} url={url} text={text}>
                    <ViberIcon width={"100%"} height={"100%"} round={true}></ViberIcon>
                    <span style={{color: 'black', padding: '5%', textAlign: 'center', marginLeft: 'auto', marginRight:'auto'}}>Viber</span>
                </ViberShareButton>
                <FacebookShareButton style={{margin:"3%", display: 'flex', flexDirection: 'column', height:'fit-content', width: '15%'}} url={url} text={text}>
                    <FacebookIcon width={"100%"} height={"100%"} round={true}></FacebookIcon>
                    <span style={{color: 'black', padding: '5%', textAlign: 'center', marginLeft: 'auto', marginRight:'auto'}}>Facebook</span>
                </FacebookShareButton>
            </div>
        </div>
        <span className="fr_second_title">Список ваших друзей</span>

        <div className="fr_list">
            {!loading && fr.length !==0 && fr.friends.map((friend) => (
                <div className="fr_card">
                    <img src='img/Avatar.png'/>
                    <span>{friend.name}</span>
                </div>
            ))}
            
        </div>


    </div>

    
)});

export default Friends;